import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import MenuPage from './pages/MenuPage';
import StoryPage from './pages/StoryPage';
import ContactPage from './pages/ContactPage';
import PageNotFound from './pages/PageNotFound';

 const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <HomePage></HomePage>
    )
  },
  {
    path: '/menu',
    element: (
      <MenuPage></MenuPage>
    )
  },
  {
    path: '/our-spice-story',
    element: (
      <StoryPage></StoryPage>
    )
  },
  {
    path: '/contact-us',
    element: (
      <ContactPage></ContactPage>
    )
  },
  
  {
    path: '*',
    element: (
      <PageNotFound></PageNotFound>
    )
  },
 ]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
