import React from 'react'
import Navbar from '../components/Navbar';
import OurStory from '../components/OurStory';
import Footer from "../components/Footer";
const StoryPage = () => {
  return (
    <div>
      <Navbar page="story"></Navbar>
      <OurStory></OurStory>
      <Footer></Footer>

    </div>
  )
}

export default StoryPage