import React from "react";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import Footer from "../components/Footer";

const HomePage = () => {
  return (
    <div>
      <Navbar page="home"></Navbar>
      <Home></Home>
      <Footer></Footer>
    </div>
  );
};

export default HomePage;
