import React from 'react'
import Navbar from '../components/Navbar';
import Contact from '../components/Contact';
import Footer from "../components/Footer";

const ContactPage = () => {
  return (
    <>
      <Navbar page="contact"></Navbar>
      <Contact></Contact>
      <Footer></Footer>
    </>
  )
}

export default ContactPage