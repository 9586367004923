import React from "react";
import Chaskka from "../assets/chaskkalogo.png";
import { FooterCopyright, FooterIcon, FooterTitle } from "flowbite-react";
import {
  BsFacebook,
  BsInstagram,
 
} from "react-icons/bs";
import { Link } from "react-router-dom";
const Footer = () => {

  const scrollHome = () =>{
    window.scrollTo(0,0);
    }

  return (
    <>
      <div className="footer">
        <div className="footerIntro">
          <img src={Chaskka} className="chaska" alt="Chaskka Logo" />
          <span className="intro">
            Chaskka is more than just a brand ~ it's a testament to our commitment
            to spreading the joy of authentic masala flavors far and wide. With
            every bite, savor the lingering notes of our masalas, each one
            leaving you craving for more.
          </span>
        </div>
        
        <hr
          data-testid="footer-divider"
          class=" w-full divider sm:mx-auto lg:my-2"
        ></hr>

        <div className="footerNav">
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <FooterIcon
              href="https://www.facebook.com/profile.php?id=61559028831241"
              icon={BsFacebook}
              className="icon hover-scale"
            />
            <FooterIcon
              href="https://www.instagram.com/chaskkafrogner?igsh=MXh3ZnU3ZHB2b3ZnbA=="
              icon={BsInstagram}
              className="icon hover-scale"
            />
            
          </div>
          <div className="footerMenu">
            <Link to={"/"}>
              <FooterTitle
              onClick={scrollHome}
                title="HOME"
                className="hover-scale text-primary fw-bold"
              />
            </Link>
            <span className="pie"> |</span>
          <Link to={"/our-spice-story"}>
              <FooterTitle
              onClick={scrollHome}
                title="OUR SPICE STORY"
                className="hover-scale text-primary fw-bold"
              />
            </Link>
            <span className="pie"> |</span>
            <Link to={"/menu"}>
              <FooterTitle
                title="MENU"
                onClick={scrollHome}
                className="hover-scale text-primary fw-bold"
              />
            </Link>
           
            <span className="pie">|</span>

            

            <Link to={"/contact-us"}>
              <FooterTitle
              onClick={scrollHome}
                title="CONTACT US"
                className="hover-scale text-primary fw-bold"
              />
            </Link>
          </div>
          <FooterCopyright
            href="#"
            by="Chaskka™"
            year={2024}
            className="icon hover-scale"
          />
        </div>
      </div>
      {/* </Footer> */}
    </>
  );
};

export default Footer;
