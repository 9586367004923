import React from "react";
import { FooterIcon } from "flowbite-react";
import { BsTelephoneFill, BsEnvelopeFill, BsPinMapFill } from "react-icons/bs";
import Char1 from "../assets/character1.png";

const Contact = () => {
  return (
    <div className="contact">
       <div className="contactBG">
        <div className="text"> #chaskka</div>
        Embark on a flavorful journey with us as we delve into the rich tapestry
        of Indian spice tradition. Our story began with a quest for the genuine
        essence of blended spices, inspired by the age-old Indian practice of
        crafting unique blends at home. At the heart of our journey lies our
        signature Tikka Masala blend—a harmonious fusion of carefully selected
        spices that ignite the palate and inspire culinary creativity. From the
        aromatic depths of our Biryani Masala to the velvety richness of our
        Butter Chicken Masala, each blend is a tribute to the artistry of Indian
        cuisine.
      </div>
      <img src={Char1} alt="" className="charImg1" />
     
      <div className="footerContact">
        <div className="contact_item">
          <FooterIcon
            href="#"
            icon={BsTelephoneFill}
            className="icon hover-scale"
          />
          <span className="details"> (+47) 40297874</span>
        </div>
        <div className="contact_item">
          <FooterIcon
            href="#"
            icon={BsEnvelopeFill}
            className="icon hover-scale"
          />
          <span className="details"> info@chaskka.com</span>
        </div>
        <div className="contact_item">
          <FooterIcon
            href="#"
            icon={BsPinMapFill}
            className="icon hover-scale"
          />
          <span className="details">Frognerveien 1B, 0257, Oslo</span>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Contact;
