import Navbar from '../components/Navbar';
import Menu from '../components/Menu';
import Footer from "../components/Footer";
const MenuPage = () => {

  return (
    <div>
      <Navbar page="menu"></Navbar>
      <Menu ></Menu>
      <Footer></Footer>

    </div>
  )
}

export default MenuPage