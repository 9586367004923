import React from "react";

const Menu = () => {
 
  return (
    
    <div className="menu">
      
      <iframe src={"https://ninito.no/shop/chaskka-oslo"} title="Loaded Website" width="100%" height="700px" />
    </div>
  );
};

export default Menu;
