import React from "react";
import Story from "../assets/story.mp4";
import Food1 from "../assets/2.jpg";
import Food3 from "../assets/12.jpg";
import Food4 from "../assets/6.jpg";
import Food5 from "../assets/20.jpg";
import Food6 from "../assets/23.jpg";
import Food7 from "../assets/16.jpg";
import Food8 from "../assets/8.jpg";

const OurStory = () => {
  return (
    <div className="menu">
      <video src={Story} autoPlay loop muted></video>
      <div className="text5">
        Every bite is a Chaskka (obsession) full of flavors
      </div>
      <div className="text6">
        We invite you to explore the versatility of our masalas, as we reimagine
        traditional recipes and infuse them with our own unique twist. Whether
        added to salads or rice bowls, infused into burgers or pizzas, our
        masalas promise to elevate every dish to new heights of flavor and
        indulgence.
      </div>
      <div className="itemBlock">
        <div className="item">
          <img className="foodImg" src={Food1} alt="" />
          <div className="text7">
            <span className="bold">Chaskka rolls</span>
            Indulge in the authentic street food experience with our flavorful
            rolls. Our spice-marinated Chaskka Fillers are perfectly rolled in
            soft flatbread and generously topped with our signature Chaskka
            Chutney.
          </div>
        </div>
        <div className="item">
          <img className="foodImg" src={Food3} alt="" />
          <div className="text7">
            <span className="bold">Salad bowl</span>
            For healthy with modern twist on tradition, try our Salad
            Bowl option. We reimagine the classic recipe by replacing the
            flatbread with a bed of fragrant rice, along with healthy lentils
            and salads.
          </div>
        </div>
        <div className="item">
          <img className="foodImg" src={Food4} alt="" />
          <div className="text7">
            <span className="bold">Biryani</span>
            Top up your mood and tantalize your taste buds with Chasska for
            Biryanis. Our Special Biryani Masala is meticulously crafted to
            enhance the richness and depth of traditional biryani.
          </div>
        </div>

        <div className="item">
          <img className="foodImg" src={Food5} alt="" />
          <div className="text7">
            <span className="bold">Tikka with Rice</span>
            You can choose between plain rice or slow-cooked aromatic rice
            infused with spices and love, along with our signature fillers. It's
            a delightful fusion of flavors and textures that will leave you
            craving more.
          </div>
        </div>
        <div className="item">
          <img className="foodImg" src={Food6} alt="" />
          <div className="text7">
            <span className="bold">Tikka in Pita</span>
            For those craving something lighter yet equally satisfying, try our
            Chaskka Fillers filled in Pita Bread. Stuffed with tender signature
            tikka masala, it's a flavor-packed journey you won't soon forget.
          </div>
        </div>
        <div className="item">
          <img className="foodImg" src={Food7} alt="" />
          <div className="text7">
            <span className="bold">Burger</span>
            Burger twists are a true delight for burger enthusiasts. Bite into
            juicy and flavorful Chaskka fillers for an experience not like any
            other. Whether as a delightful appetizer or a flavorful side, you will always crave more.  

          </div>
        </div>
        <div className="item">
          <img className="foodImg" src={Food8} alt="" />
          <div className="text7">
            <span className="bold">Pizza</span>
            Our pizzas are topped with chaskka fillers in our signature tikka
            masala, adding a burst of spice and flavor to every bite. Finished
            with crunchy peanuts and rucola, each slice will leave you
            craving more.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
